<template>
<v-container lighten-5>
        <v-row v-if="loadingForm" align="center" justify="center">
            <v-col cols="12" sm="12" md="12" lg="12">
                <Loader />
            </v-col>
        </v-row>
        <v-row v-else>
        <v-col cols="auto" md="12">
            <v-form @submit.prevent="agregar">
                <v-card elevation="0" class="rounded-xl">
                    <v-card-title>
                        <span class="headline">{{formTitle}} paciente</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn v-if="!datosVaciarPaciente" color="primary darken-1" text :to="{name:'Pacientes'}">Cancelar</v-btn>
                        <v-btn color="primary darken-1" :loading="loadingGuardar" dark @click="agregar">Guardar</v-btn>
                    </v-card-actions>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="12" md="12">
                                    Datos del paciente
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field dense outlined v-on:keyup="$data.form.tblConfPersonasnaturales[0].copnNombre1 = $event.target.value.toUpperCase()" :error-messages="errores['tblConfPersonasnaturales[0].copnNombre1']"  label="Primer Nombre" v-model="form.tblConfPersonasnaturales[0].copnNombre1"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field dense outlined v-on:keyup="$data.form.tblConfPersonasnaturales[0].copnNombre2= $event.target.value.toUpperCase()" label="Segundo Nombre" v-model="form.tblConfPersonasnaturales[0].copnNombre2"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field dense outlined v-on:keyup="$data.form.tblConfPersonasnaturales[0].copnApellido1 = $event.target.value.toUpperCase()"  :error-messages="errores['tblConfPersonasnaturales[0].copnApellido1']" label="Primer Apellido" v-model="form.tblConfPersonasnaturales[0].copnApellido1"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field dense outlined v-on:keyup="$data.form.tblConfPersonasnaturales[0].copnApellido2 = $event.target.value.toUpperCase()" label="Segundo Apellido" v-model="form.tblConfPersonasnaturales[0].copnApellido2"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field dense outlined v-on:keyup="$data.form.copeIdentificacion = $event.target.value.toUpperCase()"  label="Identificacion" :error-messages="errores['copeIdentificacion']"  v-model="form.copeIdentificacion"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-autocomplete dense outlined  v-model="form.copeTipoIdentificacion" :error-messages="errores['copeTipoIdentificacion']" :items="tipoidentificacion" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de identificacion" clearable></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field dense outlined   label="Telefono" v-model="form.copeTelefono"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field dense outlined   label="Telefono alternativo" v-model="form.copeTelalternativo"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field dense outlined v-on:keyup="$data.form.copeEmail = $event.target.value.toUpperCase()"  label="Email" v-model="form.copeEmail"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field dense outlined v-on:keyup="$data.form.copeDireccion = $event.target.value.toUpperCase()"  label="Direccion" v-model="form.copeDireccion"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-autocomplete dense outlined v-model="form.copeTipoPais" :error-messages="errores['copeTipoPais']"  :items="tipopais" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Pais" clearable @change="getDepa()"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="3" v-if="form.copeTipoPais">
                                    <v-autocomplete dense outlined v-model="form.copeTipoDepartamento"  :error-messages="errores['copeTipoDepartamento']" :items="tipodepartamento" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Departamento" clearable @change="getMuni()"></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="3" v-if="form.copeTipoDepartamento">
                                    <v-autocomplete dense outlined v-model="form.copeTipoMunicipio"  :error-messages="errores['copeTipoMunicipio']" :items="tipomunicipio" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Municipio" clearable></v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                            <v-row class="mt-2">

                                <v-col cols="12" sm="12" md="3">
                                    <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field  dense outlined v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento" label="Fecha de nacimiento" append-icon="event" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker locale="ES" v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento" :error-messages="errores['tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento']"  @input="menu = false"></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" sm="12" md="3">
                                    <v-autocomplete dense outlined  v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoPaisnacieminto" :items="tipopaisn" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Pais de nacimiento" clearable @change="getDepan()"></v-autocomplete>

                                </v-col>

                                <v-col cols="12" sm="12" md="3" v-if="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoPaisnacieminto">
                                    <v-autocomplete dense outlined  v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoDepartamentonacimiento" :items="tipodepartamenton" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Departamento de nacimiento" clearable @change="getMunin()"></v-autocomplete>

                                </v-col>
                                <v-col cols="12" sm="12" md="3" v-if="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoDepartamentonacimiento">
                                    <v-autocomplete dense outlined  v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoMunicipionacimiento" :items="tipomunicipion" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Municipio de nacimiento" clearable></v-autocomplete>

                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-text-field v-if="nuevo" append-icon="search" @click:append="nuevo=!nuevo" dense outlined  v-on:keyup="$data.form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoBarrio = $event.target.value.toUpperCase()" label="Barrio" v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoBarrio"></v-text-field>
                                    <v-autocomplete v-if="!nuevo" append-outer-icon="edit" @click:append-outer="nuevo=!nuevo"  dense  outlined v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoBarrio" :items="barrios.barrios" item-text="coppTipoBarrio" item-value="coppTipoBarrio" label="Barrio" :search-input.sync="barrios.search" :error-messages="barrios.error" @keyup="filtrarBarrios()"  required hide-no-data hide-selected  clearable> </v-autocomplete>
                                 </v-col>

                                <v-col cols="12" sm="12" md="3">
                                    <v-autocomplete dense outlined  v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoZona" :items="tipozona" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Zona" clearable></v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="12" md="3">
                                    <v-autocomplete dense outlined  v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoPoblacion" :items="tipopoblacion" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Poblacion" clearable></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-autocomplete dense outlined  v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoEtnia" :items="tipoetnia" item-text="comaNombrelargo" item-value="comaNombrelargo" item-color="primary" label="Etnia" clearable></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-autocomplete dense outlined  v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoNiveleducativo" :items="tiponvleducacion" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Nivel educativo" clearable></v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="12" md="3">
                                    <v-autocomplete dense outlined  v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoOcupacion" :items="tipoocupacion" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Ocupacion" clearable></v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="12" md="3">
                                    <v-autocomplete dense outlined  v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoSexo" :items="tiposexo" :error-messages="errores['tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoSexo']" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Sexo" clearable></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-autocomplete dense outlined  v-model="form.coppTipoGenero" :items="tipogenero" :error-messages="errores['coppTipoGenero']" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Genero" clearable></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-autocomplete dense outlined  v-model="form.coppTipoOrientacion" :items="tipoorientacion" :error-messages="errores['coppTipoOrientacion']" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Orientacion sexual" clearable></v-autocomplete>
                                </v-col>

                                <v-col cols="12" sm="12" md="3">
                                    <v-autocomplete dense outlined  v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoEstado" :items="tipoestado" :error-messages="errores['tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoEstado']" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Estado" clearable></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-autocomplete dense outlined  v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoEstadocivil" :items="tipoestadocivil" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Estado civil" clearable></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="3">
                                    <v-autocomplete dense outlined  v-model="form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoIncapacidad" :items="tipoincapacidad" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Discapacidad" clearable></v-autocomplete>
                                </v-col>
                                <!-- <v-col cols="12" sm="12" md="4">
                                    <label for="">Firma</label>
                                    <br>
                                    <canvas id="canvasfirma" width="400" height="180" style="border: 1px solid rgb(170, 170, 170)"></canvas>
                                    <canvas id="canvasfirma2" style="display:none"></canvas>
                                </v-col> -->
                                <!-- <v-col cols="12" sm="12" md="4" v-if="firmaActual">
                                    <label for="">Firma Actual</label>
                                    <br>
                                    <v-img :src="firmaActual" width="400" height="180" style="border: 1px solid rgb(170, 170, 170)"></v-img>
                                </v-col>
                                <v-col cols="12" sm="12" md="12" style="display: inline-block; margin-left: 10px; margin-top: 32px">
                                    <v-btn id="clear-canvas" color="primary" @click="canvas.clear()" item-color="primary">Limpiar</v-btn><br>
                                </v-col> -->
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                </v-card>
            </v-form>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import generales from '../../../mixins/generales.js';
export default {
    mixins: [generales],
    name: 'Createpaciente',
    props: {
        datosVaciarPaciente:Object
    },
    data() {
        return {
            menu: false,
            url:'sistema/personas/',
            canvas:null,
            canvas2:null,
            nuevo:false,
            form: {
                copeId: null,
                copeTelefono: null,
                copeEmail: null,
                copeDireccion: null,
                copeIdentificacion: null,
                copeTipoIdentificacion: null,
                copeTipoPais: null,
                copeTipoDepartamento: null,
                copeTipoMunicipio: null,
                copeTelalternativo:null,
                tblConfPersonasnaturales: [{
                    copnNombre1: null,
                    copnNombre2: null,
                    copnApellido1: null,
                    copnApellido2: null,
                    tblConfPersonaspacientes: [{
                        coppFechanacimiento: new Date().yyyymmdd(),
                        coppTipoPaisnacieminto: null,
                        coppTipoDepartamentonacimiento: null,
                        coppTipoMunicipionacimiento: null,
                        coppTipoBarrio: null,
                        coppTipoZona: null,
                        coppTipoPoblacion: null,
                        coppTipoNiveleducativo: null,
                        coppTipoOcupacion: null,
                        coppTipoSexo: null,
                        coppTipoEstado: null,
                        coppTipoEstadocivil: null,
                        coppTipoIncapacidad: null,
                        coppTipoEtnia:null
                    }]
                }],
            },
            tipoidentificacion: [],
            tipodepartamento: [],
            tipomunicipio: [],
            tipopais: [],
            tipodepartamenton: [],
            tipomunicipion: [],
            tipopaisn: [],
            tipoestado: [],
            tipozona: [],
            tipopoblacion: [],
            tiponvleducacion: [],
            tipoocupacion: [],
            tiposexo: [],
            tipoestadocivil: [],
            tipoincapcidad: [],
            tipoetnia: [],
            tipogenero: [],
            tipoorientacion: [],
            firmaActual:null,
            barrios:{
                barrios:[],
                search:null,
                error:""
            }
        }
    },
    created: async function () {
        this.loadingForm = true;
        let {tipoidentificacion,tipopais,tipoestado,tipozona ,tipopoblacion ,tiponvleducacion,tipoocupacion,tiposexo,tipoestadocivil,tipoincapacidad,tipoetnia,tipogenero,tipoorientacion } = await this.$apiService.index("sistema/personas/generalesCreatePaciente").then(data => data);
        this.tipoidentificacion = tipoidentificacion;
        this.tipopaisn = this.tipopais = tipopais;
        this.tipoestado = tipoestado;
        this.tipozona =tipozona;
        this.tipopoblacion = tipopoblacion;
        this.tiponvleducacion = tiponvleducacion;
        this.tipoocupacion = tipoocupacion;
        this.tiposexo = tiposexo;
        this.tipoestadocivil = tipoestadocivil;
        this.tipoincapacidad = tipoincapacidad;
        this.tipoetnia = tipoetnia;
        this.tipogenero = tipogenero;
        this.tipoorientacion = tipoorientacion;
        this.form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoEstado = this.tipoestado.find( e => e.comaNombrecorto=='ACTIVO').comaId;
        this.limpiar();
        if (this.$route.params.id) {
            await this.editar(this.$route.params.id);
            /* let data = await this.$apiService.index(this.url + `getFirmaPaciente?copeId=${this.$route.params.id}`).then(data => data);
            if(data.mensaje != undefined && data.mensaje !='No data'){
                this.firmaActual = 'data:image/png;base64,'+data;
            }*/
        }
        if (this.datosVaciarPaciente != undefined) {
            if (this.datosVaciarPaciente.copeId) {
                await this.editar(this.datosVaciarPaciente.copeId);
            }
        }
       this.loadingForm = false;
    },
    computed: {
        formTitle() {
            return this.isNew === true ? "Registrar nuevo" : "Actualizar";
        },

    },
    mounted(){
        // this.canvas = new fabric.Canvas(document.querySelector("#canvasfirma"), {
        //         height: 180,
        //         width: 400,
        //         selectionColor: "#90ccb7",
        //         backgroundColor: 'rgba(0,0,0,0)',
        //         isDrawingMode: true
        //     });   
        // this.canvas2 = new fabric.Canvas(document.querySelector("#canvasfirma2"), {
        //     height: 180,
        //     width: 400,
        //     selectionColor: "#90ccb7",
        //     backgroundColor: 'rgba(0,0,0,0)'
        // });  
         
    },
    
    methods: {
        async filtrarBarrios(){
            if (this.barrios.search && this.barrios.search.length > 4) {
            this.barrios.error ="";
            this.barrios.barrios =  await this.$apiService.index(`${this.url}barrios?barrio=${this.barrios.search}`).then(data => data);
            }else{
                this.barrios.barrios = []
                this.barrios.error = "Escriba 4 letras como minimo"
            }
        },
        async getDepa() {
            this.tipodepartamento = await this.$apiService.index("sistema/maestra/index/TBL_TIPODEPART/" + this.form.copeTipoPais).then(data => data);
        },
        async getMuni() {
            this.tipomunicipio = await this.$apiService.index("sistema/maestra/index/TBL_TIPOMUNICIPIO/" + this.form.copeTipoDepartamento).then(data => data);
        },
        async getDepan() {
            this.tipodepartamenton = await this.$apiService.index("sistema/maestra/index/TBL_TIPODEPART/" + this.form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoPaisnacieminto).then(data => data);
        },
        async getMunin() {
            this.tipomunicipion = await this.$apiService.index("sistema/maestra/index/TBL_TIPOMUNICIPIO/" + this.form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoDepartamentonacimiento).then(data => data);
        },
        async editar(id) {
            try {
                this.form = await this.$apiService.index(this.url + "view/" + id + "/pacientes").then(data => data);
                await this.getDepa();
                await this.getMuni();
                await this.getDepan();
                await this.getMunin();
                this.barrios.search = this.form.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppTipoBarrio;
                await this.filtrarBarrios();
                this.isNew = false;
            } catch (error) {
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        },
            
        async agregar() {
             try {
                this.errores = [];
                this.loadingGuardar = true;
                let data = (this.isNew) ? await this.$apiService.create(`${this.url}paciente/create/pacientes`, this.form).then((data) => data) :
                    await this.$apiService.update(`${this.url}paciente/update/${this.form.copeId}/pacientes`, this.form).then((data) => data);
                if (data) {
                    /* let formData = new FormData();
                                  this.canvas.getElement().toBlob(async(blob) =>{
                                       this.canvas2.getElement().toBlob(async(blob2) =>{
                                            if(blob.size != blob2.size){
                                                formData.append('file', blob);
                                                 await this.$apiService.createFile(this.url + `firmaPaciente?copeIdentificacion=${this.form.copeIdentificacion}&copeId=${id}`, formData).then(data => data);
                                            }
                                        },'image/png');
                                },'image/png');*/
                    if (this.datosVaciarPaciente) {
                        if (this.datosVaciarPaciente.nuevo) {
                            this.$emit('validar', { tipo: 'nuevo', paciente: data });
                        }
                        if (this.datosVaciarPaciente.copeId) {
                            this.$emit('validar', { tipo: 'editar' });
                        } 
                    } else {
                             this.$swal.fire({
                                title: "Completado!",
                                text: "Registro exitoso",
                                icon: "success",
                                confirmButtonText: "Ok"
                            });
                            this.$router.push("/sistema/pacientes");
                    }
                   
                }

            } catch (error) {
                if (error.response && 422 == error.response.status) {
                    this.errores = error.response.data.errors;
                } else {
                    if (error.response && 403 == error.response.status) {
                        this.$router.push('/403');
                    } else {
                       if (error.response && 400 == error.response.status && error.response.data.message) {
                        this.$swal.fire({
                            title: "Ups!",
                            text: error.response.data.message,
                            icon: "info"
                        });
                    } else {
                        this.$swal.fire({
                            title: "Algo sucedio!",
                            text: "contacta con el administrador",
                            icon: "error"
                        });
                    }
                    }

                }
            }
            this.loadingGuardar = false;
        },
        limpiar() {
            this.form = {
                copeId: null,
                copeTelefono: null,
                copeEmail: null,
                copeDireccion: null,
                copeIdentificacion: null,
                copeTipoIdentificacion: null,
                copeTipoPais: null,
                copeTipoDepartamento: null,
                copeTipoMunicipio: null,
                copeTelalternativo:null,
                tblConfPersonasnaturales: [{
                    copnNombre1: null,
                    copnNombre2: null,
                    copnApellido1: null,
                    copnApellido2: null,
                    tblConfPersonaspacientes: [{
                        coppFechanacimiento: new Date().yyyymmdd(),
                        coppTipoPaisnacieminto: null,
                        coppTipoDepartamentonacimiento: null,
                        coppTipoMunicipionacimiento: null,
                        coppTipoBarrio: null,
                        coppTipoZona: null,
                        coppTipoPoblacion: null,
                        coppTipoNiveleducativo: null,
                        coppTipoOcupacion: null,
                        coppTipoSexo: null,
                        coppTipoEstado: null,
                        coppTipoEstadocivil: null,
                        coppTipoEtnia: null,
                    }]
                }],

            };
        },
  
    }

}
</script>
