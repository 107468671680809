<template>
<v-container lighten-5>
    <v-row>
        <v-col cols="12" sm="12" md="12">
            <span class="mx-2 secondary--text display-2 float-left">Citas</span>
        </v-col>
        <v-col cols="12" sm="12" md="12">
            <v-menu transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on }">
                    <v-btn fab color="white" class="primary--text float-left" :loading="LoadingExp" small v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="exporttabla">
                        <v-list-item-title>Exportar</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-btn class="mx-2 float-right" fab small dark color="accent" @click="verFiltros=!verFiltros">
                <v-icon dark>filter_list</v-icon>
            </v-btn>
        </v-col>
          <v-col cols="12" sm="12" md="12" v-if="tabla">
            <v-card elevation="0" class="rounded-xl">
                <v-data-iterator :items="lista" :page.sync="page" :loading="loadingTable" :item-key="tabla.itemKey" :items-per-page="10" single-expand hide-default-footer :sort-by="tabla.orderBy" :sort-desc="tabla.orderDesc">
                    <template v-slot:header>
                        <Dfiltros v-if="verFiltros" :onBuscar="search" v-bind:filtros="filtros" />
                        <v-toolbar dark class="mb-1">
                            <v-btn v-if="!coppId" class="mx-2 primary--text" tile color="white" @click.stop="dialog = true">
                                agregar
                            </v-btn>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <div class="flex-grow-1"></div>
                        </v-toolbar>
                    </template>
                    <template v-slot:default="{ items, isExpanded, expand }">
                        <CardTable v-bind:table="{ tabla, data:{ items, isExpanded, expand }}"></CardTable>
                    </template>
                    <template v-slot:no-data>
                        <v-row class="mt-2" align="center" justify="center">
                            <v-col cols="10" sm="10" md="10" lg="10">
                                <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                    No se encontro nada.
                                </v-alert>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:loading>
                        <v-row class="mt-2" align="center" justify="center">
                            <v-col cols="12" sm="12" md="12" lg="12">
                                <Loader />
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:footer>
                        <Paginacion v-on:cambiarPagina="page = $event" :onSearch="search" v-bind:propiedades="{filtros,page,pageCount,Count}" />
                    </template>
                </v-data-iterator>
            </v-card>
        </v-col>
        <v-form @submit.prevent="agregar">
                <v-dialog v-model="dialog" persistent width="1200px" min-width="1200px">
                    <v-card>
                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-container>
                                <template v-if="form.coppId && tipo == 'CAPITADO'">
                                    <v-alert text prominent dense type="success" v-if="bd.length > 0">
                                        El paciente se encuentra en las base de datos de <template v-for="b in bd"> {{ ','+ b.cocoNombre  }}</template>
                                    </v-alert>
                                    <v-alert text prominent dense type="error" v-else>
                                        El paciente no se encuentra en ninguna base de datos para el mes de {{ meses[ new Date().getMonth()]}}.
                                    </v-alert>
                                </template>

                                <v-row align="center">
                                    <v-col cols="12" sm="12" md="1" v-if="tipo=='EVENTO'">
                                        <template v-if="!nuevo">

                                            <v-icon color="success" style="margin-bottom: 20px;margin-left: 50px;" @click="nuevo=!nuevo;">mdi-account-plus</v-icon>

                                        </template>
                                        <template v-else>

                                            <v-icon color="primary" style="margin-bottom: 20px;margin-left: 50px;" @click="nuevo=!nuevo;">mdi-account-search</v-icon>

                                        </template>

                                    </v-col>
                                    <v-col cols="12" sm="12" md="9">

                                        <v-autocomplete outlined :error="$v.form.coppId.$error" v-model="form.coppId" :items="pacientes" :loading="isLoading" :search-input.sync="searchP" @keypress.enter="searchPaciente" @change="vaciarpaciente(null)" no-filter hide-no-data hide-selected item-text="tblConfPersonasnaturales[0].copnNombre1" item-value="tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId" item-color="primary" label="Paciente" clearable>
                                            <template v-slot:selection="data">
                                                {{data.item.tblConfPersonasnaturales[0].copnNombre1 + " "+data.item.tblConfPersonasnaturales[0].copnNombre2 + " "+data.item.tblConfPersonasnaturales[0].copnApellido1 + " "+data.item.tblConfPersonasnaturales[0].copnApellido2}}
                                            </template>
                                            <template v-slot:item="data">
                                                <template v-if="(typeof data.item !== 'object')">
                                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                                </template>
                                                <template v-else>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.tblConfPersonasnaturales[0].copnNombre1 + ' '+data.item.tblConfPersonasnaturales[0].copnNombre2 + ' '+data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' +data.item.tblConfPersonasnaturales[0].copnApellido2"></v-list-item-title>
                                                        <v-list-item-subtitle v-html="data.item.copeIdentificacion">

                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="2">
                                        <v-switch v-model="tipo" true-value="EVENTO" false-value="CAPITADO" :label="tipo"></v-switch>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4" v-if="tipo=='EVENTO' && guardopaciente">
                                        <v-autocomplete outlined v-model="form.cocoId" :items="contratos" item-text="cocoNombre" item-value="cocoId" item-color="primary" label="Contrato" @change="getServicios" clearable>
                                            <template v-slot:selection="data">
                                                {{data.item.cocoNombre +" - "+data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}
                                            </template>
                                            <template v-slot:item="data">
                                                <template v-if="(typeof data.item !== 'object')">
                                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                                </template>
                                                <template v-else>
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ data.item.cocoNombre}}</v-list-item-title>
                                                        <v-list-item-subtitle> {{data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="4" v-else-if="bd.length > 0 && guardopaciente">
                                        <v-autocomplete outlined v-model="form.cbdpId" :items="bd" item-text="cocoNombre" item-value="cbdpId" item-color="primary" label="EPS contrada" @change="getServicios" clearable>
                                            <template v-slot:item="data">
                                                <template v-if="(typeof data.item !== 'object')">
                                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                                </template>
                                                <template v-else>
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{data.item.cocoNombre}}</v-list-item-title>
                                                        <v-list-item-subtitle> {{data.item.coemNombre}}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </template>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="8" v-if="(form.coppId && guardopaciente && bd.length > 0) || (form.coppId && guardopaciente && form.cocoId)">
                                        <v-autocomplete outlined :error="$v.form.cocsId.$error" required v-model="form.cocsId" :items="servicios" item-text="tblConfServicios[0].coseNombre" item-value="cocsId" item-color="primary" label="Servicio" clearable @change="getEmpleados">
                                            <template v-slot:selection="data">
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="data.item.tblConfServicios[0].coseNombre"></v-list-item-title>
                                                    <v-list-item-subtitle class="primary--text" v-html="data.item.tblConfContrato.tblConfPersonasempresas[0].coemNombre"></v-list-item-subtitle>
                                                    <v-list-item-subtitle v-html="data.item.tblConfCup.cocuNombre"></v-list-item-subtitle>
                                                     <v-list-item-subtitle v-html="data.item.tipo?data.item.tipo.comaNombrelargo:''"></v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                            <template v-slot:item="data">
                                                <template v-if="(typeof data.item !== 'object')">
                                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                                </template>
                                                <template v-else>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.tblConfServicios[0].coseNombre"></v-list-item-title>
                                                        <v-list-item-subtitle class="primary--text" v-html="data.item.tblConfContrato.tblConfPersonasempresas[0].coemNombre"></v-list-item-subtitle>
                                                        <v-list-item-subtitle v-html="data.item.tblConfCup.cocuNombre"></v-list-item-subtitle>
                                                         <v-list-item-subtitle v-html="data.item.tipo?data.item.tipo.comaNombrelargo:''"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </template>
                                        </v-autocomplete>

                                    </v-col>

                                    <v-col cols="12" sm="12" md="12" v-if="form.cocsId && form.coppId && guardopaciente">
                                        <v-autocomplete outlined :error="$v.form.coprId.$error" required v-model="form.coprId" :items="empleados" :filter="(item, queryText, itemText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' '+item.tblConfPersonasnaturales[0].copnNombre2 + ' '+item.tblConfPersonasnaturales[0].copnApellido1 + ' '+item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1 " item-text="tblConfPersonasnaturales[0].copnNombre1" item-value="tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprId" item-color="primary" label="Medico" @change="disponibilidad" clearable>
                                            <template v-slot:selection="data">
                                                {{data.item.tblConfPersonasnaturales[0].copnNombre1 + " "+data.item.tblConfPersonasnaturales[0].copnNombre2 + " "+data.item.tblConfPersonasnaturales[0].copnApellido1 + " "+data.item.tblConfPersonasnaturales[0].copnApellido2}}
                                            </template>
                                            <template v-slot:item="data">
                                                <template v-if="(typeof data.item !== 'object')">
                                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                                </template>
                                                <template v-else>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-html="data.item.tblConfPersonasnaturales[0].copnNombre1 + ' '+data.item.tblConfPersonasnaturales[0].copnNombre2 + ' '+data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' +data.item.tblConfPersonasnaturales[0].copnApellido2"></v-list-item-title>
                                                        <v-list-item-subtitle v-html="data.item.copeIdentificacion">

                                                        </v-list-item-subtitle>
                                                        <v-list-item-subtitle v-html="data.item.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].cargo.comaNombrelargo">

                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </template>
                                            </template>
                                        </v-autocomplete>

                                    </v-col>
                                     <v-col cols="12" sm="12" md="3" v-if="form.coppId">
                                          <v-autocomplete outlined dense required v-model="form.citaTipoDetalle" :items="tipodetalle" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Detalle" clearable></v-autocomplete>
                                      </v-col>
                                    <v-col cols="12" sm="12" md="3" v-if="form.coppId && guardopaciente">
                                        <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" v-if="form.coppId && form.coprId">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field required outlined :error="$v.form.citaFecha.$error" v-model="form.citaFecha" label="Fecha de la cita" append-icon="event" readonly v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker locale="ES" v-model="form.citaFecha" @input="menu = false" @change="disponibilidad"></v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <!-- <v-col cols="12" sm="12" md="3" v-if="form.coppId && form.coprId  && guardopaciente">
                                    <v-autocomplete outlined :error="$v.form.citaTipoJornada.$error" required v-model="form.citaTipoJornada" :items="tipojornada" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Jornada" @change="disponibilidad" clearable></v-autocomplete>
                                </v-col> -->
                                    <v-col cols="12" sm="12" md="12" v-if="form.coppId && form.coprId && form.citaFecha && !(dispo.length > 0 ) && guardopaciente">
                                        <v-alert text prominent dense type="error">
                                            El medico no tiene horario disponible para esta fecha.
                                        </v-alert>
                                    </v-col>
                                    <!-- <v-col cols="12" sm="12" md="3" v-if="form.coppId && form.citaTipoJornada && form.coprId && dispo.length > 0 && guardopaciente">
                                    <v-autocomplete outlined  v-model="form.citaTipoSala" :items="tiposala" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Sala" clearable></v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="12" md="3" v-if="form.coppId && form.citaTipoJornada && form.coprId && dispo.length > 0 && guardopaciente">
                                    <v-autocomplete outlined :error="$v.form.citaTipoTurno.$error" required v-model="form.citaTipoTurno" :items="tipoturno" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Turno" clearable></v-autocomplete>
                                </v-col> -->
                                    <v-col cols="12" sm="12" md="4" v-if="form.coppId && /*form.citaTipoJornada &&*/ form.coprId && dispo.length > 0 && guardopaciente">
                                        <v-autocomplete outlined :error="$v.form.citaHora.$error" required v-model="form.citaHora" :items="tipohoras" item-text="horaCantidad" item-value="hora" item-color="primary" label="Hora" clearable></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6" v-if="form.coppId && form.citaTipoJornada && form.coprId && dispo.length > 0 && guardopaciente">
                                        <v-autocomplete outlined :error="$v.form.citaTipoEstado.$error" required v-model="form.citaTipoEstado" :items="tipoestado.filter(e=>e.comaNombrecorto == 'ACTIVA')" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Estado" clearable></v-autocomplete>
                                    </v-col>

                                </v-row>

                            </v-container>
                            <template v-if="(datosVaciarPaciente && !guardopaciente)">
                                <Createpaciente v-on:validar="vaciarpaciente($event)" v-bind:datosVaciarPaciente="datosVaciarPaciente"  />
                            </template>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <div class="flex-grow-1"></div>
                            <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                            <v-btn color="primary darken-1" :loading="loadingCita" dark @click="agregar" v-if="form.coppId && form.coprId && dispo.length > 0">Guardar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
        </v-form>
    <v-dialog v-model="dialog2" width="1200px" max-width="1200px">
        <v-card>
          <v-card-title class="text-h5 primary">HISTORIA</v-card-title>
          <v-card-text>
              <v-container>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                         <template v-if="pdfObj">
                            <PdfHistoria v-bind.sync="pdfObj" />
                        </template>
                    </v-col>                   
                </v-row>              
              </v-container>                              
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog2 = false"> Cerrar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
     <v-dialog v-model="dialog3" width="1200px" max-width="1200px">
        <v-card>
          <v-card-title class="text-h5 primary">Ingresar</v-card-title>
          <v-card-text>
              <v-container>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                         <template v-if="ingreso">
                            <Facturaciones v-bind.sync="ingreso" />
                        </template>
                    </v-col>                   
                </v-row>              
              </v-container>                              
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog3 = false"> Cerrar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog4" width="1200px" max-width="1200px">
        <v-card>
          <v-card-title class="text-h5 green lighten-2"> E-MAILS </v-card-title>
          <v-card-text>
              <v-container>
                <v-row>
                <v-col cols="12" sm="12" md="12">
                    <v-text-field outlined  label="Correo" v-model="correo" ></v-text-field>
                </v-col>
              </v-row>
              </v-container>           
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :loading="loadingEmail" text @click="enviarRecordatorio()">Enviar</v-btn>
            <v-btn color="primary" text @click="dialog4 = false;correo = null;pdfObj = null;"> Cancelar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
</v-container>
</template>

<script>
import {
  required
}
from 'vuelidate/lib/validators'
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import {
  documentos
}
from "../../utils/documentos";
import generales from '../../mixins/generales.js';
export default {
  props:{
    coppId:Number,
  },
  components: {
    Createpaciente: () => import("../sistema/pacientes/Create"),
    PdfHistoria: () => import("../../components/PdfHistoria"),
    Facturaciones: () => import("./Facturaciones.vue")
},
  mixins: [generales],
  data() {
    return {
      guardopaciente: false,
      pacienteactual: null,
      datosVaciarPaciente: null,
      loadingCita: false,
      nuevo: false,
      menu: false,
      LoadingExp: false,
      tipo: this.$cookies.get("DEFAULT"),
      tab: "tab-1",
      correo: "",
      loading: false,
      dialog2: false,
      dialog3: false,
      pdfObj: null,
      ingreso:null,
      pdfh: null,
      isNew: true,
      copeId: null,
      isLoading: false,
      cambio: null,
      searchP: null,
      meses: ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"],
      bd: [],
      url: "cita/citas/",
      empleados: [],
      pacientes: [],
      servicios: [],
      servicios2: [],
      tipojornada: [],
      tipoturno: [],
      tipoestado: [],
      tiposala: [],
      tipodetalle:[],
      dispo: [],
      tipohoras: [],
      paciente: null,
      form: {
        citaFecha: null,
        citaTipoJornada: null,
        citaTipoTurno: null,
        citaTipoSala: null,
        citaTipoEstado: null,
        citaTipo: 0,
        cbdpId: null,
        coppId: null,
        createdBy: this.$cookies.get("user").id,
        coprId: null,
        cocsId: null,
        cocoId: null,
        citaTipoDetalle:null
      },
      tabla: {
                itemKey: 'citaId',
                orderBy: 'citaFecha',
                orderDesc: true,
                expandible: false,
                expandibleKey: 'citaId',
                expandibleTitle: () => { return `` },
                expandibleItems: () => { return [] },
                primarios: [{
                        titulo: 'PACIENTE',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '12',
                            lg: '12'
                        },
                        class: 'primary--text subtitle-1',
                        valor: (item) => { return `${item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1} ${item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1}` },
                        show: (item) => { return item ? true : false }
                    }, {
                        titulo: 'FECHA',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '3',
                            lg: '3'
                        },
                        class: 'green--text subtitle-1',
                        valor: (item) => { return item.citaFecha },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'IDENT.',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '3',
                            lg: '3'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion },
                        show: (item) => { return item ? true : false }
                    },
                     {
                        titulo: 'SEXO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.tblConfPersonaspacientes[0].sexo.comaNombrelargo },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'EDAD',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return this.calcularEdad(item.tblConfPersonaspacientes[0].coppFechanacimiento) },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'ESTADO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '3',
                            lg: '3'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.estado.comaNombrecorto == 'FACTURADA'? 'INGRESADO': item.estado.comaNombrecorto },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'TELEFONO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono + '-'+item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelalternativo },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'DETALLE',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.detalle? item.detalle.comaNombrelargo:''  },
                        show: (item) => { return item.detalle ? true : false }
                    },

                ],
                segundarios: [{
                        titulo: 'EPS',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '6',
                            lg: '6'
                        },
                        class: 'primary--text',
                        valor: (item) => { 
                           if(item.tblConfBasedatospacientes.length > 0){
                              return (item.tblConfBasedatospacientes[0].tblConfBasedato ? ((item.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemPrincipal == 1)?'PARTICULAR':item.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemNombre):'') 
                            }else if(item.cocoId){
                              return (item.tblConfContrato ?(item.tblConfContrato.tblConfPersonasempresas[0].coemPrincipal == 1)?'PARTICULAR': item.tblConfContrato.tblConfPersonasempresas[0].coemNombre:'') 
                            }
                        },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'EMPLEADO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '6',
                            lg: '6'
                        },
                        class: 'primary--text',
                        valor: (item) => {
                           return `${item.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 } ${item.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1 } - ${item.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}` 
                        },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'SERVICIO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '6',
                            lg: '6'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre},
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'ADMISIONISTA',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '6',
                            lg: '6'
                        },
                        class: 'primary--text',
                        valor: (item) => { return (item.admisionista) ? item.admisionista.username : '' },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'JORNADA',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return (item.jornada) ? item.jornada.comaNombrecorto : '' },
                        show: (item) => { return item.jornada ? true : false }
                    },
                    {
                        titulo: 'TURNO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return (item.turno) ? item.cifaTipoTurno : '' },
                        show: (item) => { return item.turno ? true : false }
                    },
                    {
                        titulo: 'HORA',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return (item.citaHora) ? item.citaHora : '' },
                        show: (item) => { return item.citaHora ? true : false }
                    }
                ],
                expanItem: [],
                expanMenu: [],
                menu: [
                    {
                        title: 'Historia',
                        icon: 'mdi-file-pdf-box',
                        color: 'orange',
                        tipeAction: 'funcion',
                        action: (item) => { return this.pdf(item)},
                        show: (item) => { return item ? (item.estado.comaNombrecorto == 'ASISTIDA') : false }
                    },
                    {
                        title: 'Historia con historico',
                        icon: 'mdi-file-pdf-box',
                        color: 'orange',
                        tipeAction: 'funcion',
                        action: (item) => { return this.pdf(item,true)},
                        show: (item) => { return item ? (item.estado.comaNombrecorto == 'ASISTIDA') : false }
                    },
                    {
                        title: 'Recetarios',
                        icon: 'mdi-file-pdf-box',
                        color: 'orange',
                        tipeAction: 'funcion',
                        action: (item) => { return this.pdfRecetarios(item)},
                        show: (item) => { return item ? (item.estado.comaNombrecorto == 'ASISTIDA') : false }
                    },
                    {
                        title: 'Ordenes Lab',
                        icon: 'mdi-file-pdf-box',
                        color: 'orange',
                        tipeAction: 'funcion',
                        action: (item) => { return this.pdfOrdenes(item,'LAB')},
                        show: (item) => { return item ? (item.estado.comaNombrecorto == 'ASISTIDA') : false }
                    },
                    {
                        title: 'Ordenes Img DX',
                        icon: 'mdi-file-pdf-box',
                        color: 'orange',
                        tipeAction: 'funcion',
                        action: (item) => { return this.pdfOrdenes(item,'IMGDX')},
                        show: (item) => { return item ? (item.estado.comaNombrecorto == 'ASISTIDA') : false }
                    },
                    {
                        title: 'Ordenes Servicios',
                        icon: 'mdi-file-pdf-box',
                        color: 'orange',
                        tipeAction: 'funcion',
                        action: (item) => { return this.pdfOrdenes(item,'SERV')},
                        show: (item) => { return item ? (item.estado.comaNombrecorto == 'ASISTIDA') : false }
                    },
                    {
                        title: 'Ordenes Cirujia',
                        icon: 'mdi-file-pdf-box',
                        color: 'orange',
                        tipeAction: 'funcion',
                        action: (item) => { return this.pdfOrdenes(item,'CIR')},
                        show: (item) => { return item ? (item.estado.comaNombrecorto == 'ASISTIDA') : false }
                    },
                    {
                        title: 'Remiciones',
                        icon: 'mdi-file-pdf-box',
                        color: 'orange',
                        tipeAction: 'funcion',
                        action: (item) => { return this.pdfRemiciones(item)},
                        show: (item) => { return item ? (item.estado.comaNombrecorto == 'ASISTIDA') : false }
                    },
                    {
                       title: 'Incapacidades',
                        icon: 'mdi-file-pdf-box',
                        color: 'orange',
                        tipeAction: 'funcion',
                        action: (item) => { return this.pdfIncapacidad(item)},
                        show: (item) => { return item ? (item.estado.comaNombrecorto == 'ASISTIDA') : false }
                    },
                    {
                        title: 'Certificados',
                        icon: 'mdi-file-pdf-box',
                        color: 'orange',
                        tipeAction: 'funcion',
                        action: (item) => { return this.pdfCertificado(item)},
                        show: (item) => { return item ? (item.estado.comaNombrecorto == 'ASISTIDA') : false }
                    },
                     {
                        title: 'Recordatorio de cita',
                        icon: 'mdi-email-outline',
                        color: 'primary',
                        tipeAction: 'funcion',
                        action: (item) => { return this.enviar(item) },
                        show: (item) => { return item ? (item.estado.comaNombrecorto == 'ACTIVA') : false }
                    },
                  {
                        title: 'Ingresar',
                        icon: 'mdi-desktop-mac-dashboard',
                        color: 'primary',
                        tipeAction: 'funcion',
                        action: (item) => { return this.ingresar(item) },
                        show: (item) => { return item ? (item.estado.comaNombrecorto != 'CANCELADA') : false }
                    }, {
                        title: 'Cancelar',
                        icon: 'mdi-block-helper',
                        color: 'error',
                        tipeAction: 'funcion',
                        action: (item) => { return this.cancelar(item.citaId)},
                        show: (item) => { return item ? (item.estado.comaNombrecorto == 'ACTIVA') : false }
                    },
                    {
                        title: 'Eliminar',
                        icon: 'delete',
                        color: 'error',
                        tipeAction: 'funcion',
                        action: (item) => { this.eliminar(item.citaId) },
                        show: (item) => { return item ? (this.getPermiso('eliminarCitas') && item.estado.comaNombrecorto != 'ASISTIDA') || this.user.role.indexOf('ADMIN') > -1  : false }
                    },
                ]
            },
      filtros: [{
          campo:"citaFecha",
          text: "Fecha",
          value: null,
          tipo: "fecha",
          menu: false,
          searchNormal: true,
          isLoading: false,
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
          listatext: "nombre del campo que se mostrara",
          listavalue: "nombre del valor que se mandara"*/
        },
        {
          campo:"citaTipoEstado",
          text: "Estado",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          searchNormal: true,
          isLoading: false,
          lista: [],
          listatext: "comaNombrelargo",
          listavalue: "comaId"
        }, {
          campo:"coppId",
          text: "Paciente",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          lista: [],
          listatext: "tblConfPersonasnaturales[0].copnNombre1",
          listavalue: "tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId",
          buscar: 'paciente',
          isLoading: false,
          search: null,
          //filter: (item, queryText, itemText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1,
          default: true,
          defaultselect: (data) => {
            return data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2
          },
          defaultbody: (data) => {
            return {
              title: data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2,
              subtitle: data.item.copeIdentificacion
            }
          },
        },
        {
          campo:"coprId",
          text: "Empleado",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          searchNormal: true,
          isLoading: false,
          lista: [],
          listatext: "tblConfPersonasnaturales[0].copnNombre1",
          listavalue: "tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprId",
          filter: (item, queryText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1,
          default: true,
          defaultselect: (data) => {
            return data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2
          },
          defaultbody: (data) => {
            return {
              title: data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2,
              subtitle: data.item.copeIdentificacion
            }
          },
        }, {
          campo:"coseId",
          text: "Servicio",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          lista: [],
          listatext: "coseNombre",
          listavalue: "coseId",
        },
        {
          campo:"citaFecharango",
          text: "Rango de fecha",
          value: [],
          tipo: "rango",
          menu: false,
          searchNormal: true,
          isLoading: false,
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
          listatext: "nombre del campo que se mostrara",
          listavalue: "nombre del valor que se mandara"*/
        },
        {
          campo:"admisionista",
          text: "Admisionista",
          value: null,
          tipo: "text",
          search: true,
          isLoading: false,
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
          listatext: "nombre del campo que se mostrara",
          listavalue: "nombre del valor que se mandara"*/
        },
        {
           campo:"coemId",
          text: "Empresa",
          value: null,
          tipo: "lista",
          lista: [],
          listatext: "tblConfPersonasempresas[0].coemNombre",
          listavalue: "tblConfPersonasempresas[0].coemId"
        },

      ],
      secciones: [],
      campos: [],
      Historia: [],
      historial: [],
      diagnosticos: [],
      ordenes: [],
      recetarios: [],
      remisiones: [],
      contratos: [],
      empresas: [],
      dientesplaca: [],
      primerOdontograma: [],
      dientesodontograma: [],
      historiaData: null,
      loadingEmail: false,
      dialog4:false

    };
  },
  validations: {
    form: {

      citaFecha: {
        required,
      },

      /* citaTipoJornada: {
           required,
       },

       citaTipoTurno: {
           required,
       },*/

      citaTipoEstado: {
        required,
      },
      citaHora: {
        required,
      },
      coppId: {
        required,
      },

      coprId: {
        required,
      },

      cocsId: {
        required,
      },
    },
  },
  computed: {

    formTitle() {
      return this.isNew === true ? "Nuevo" : "Actualizar";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created: async function() {
      this.$store.commit('setCargaDatos', true);
      this.filtros[1].lista = this.tipoestado = await this.$apiService.index("sistema/maestra/index/ESTADOS_CITAS").then(data => data);
      this.filtros[3].lista = await this.$apiService.index("sistema/personas/index/empleados").then(data => data);
      this.filtros[4].lista = await this.$apiService.index("sistema/servicios/index/").then(data => data);
      this.empresas = this.filtros[7].lista = await this.$apiService.index("sistema/personas/index/empresas").then(data => data);
      this.tipojornada = await this.$apiService.index("sistema/maestra/index/TBL_TIPOJORNADA").then(data => data);
      this.tipoturno = await this.$apiService.index("sistema/maestra/index/TBL_TIPOTURNO").then(data => data);
      this.tiposala = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSALAS").then(data => data);
      this.tipodetalle = await this.$apiService.index("sistema/maestra/index/TBL_TIPODETACITA").then(data => data);
      this.contratos = await this.$apiService.index("sistema/contratos/index").then(data => data);
      this.contratos = this.contratos.filter(e => e.estado.comaNombrecorto == 'ACTIVO' &&  new Date(e.cocoFechainicio.replace(/-+/g, '/')).getTime() <= new Date().getTime() && new Date(e.cocoFechafinal.replace(/-+/g, '/')).getTime() >= new Date().getTime());
      await this.search(this.filtros);
      this.form.citaTipoEstado = this.tipoestado.find(e => e.comaNombrecorto == 'ACTIVA').comaId;
      this.form.citaFecha = new Date().yyyymmdd();
      this.$store.commit('setCargaDatos', false);
  },

  methods: {
    cambiar() {
      this.nuevo = !this.nuevo;
      this.form.citaFecha = null;
      this.form.createdBy = this.user.id;
      this.form.citaTipoJornada = null;
      this.form.citaTipoTurno = null;
      this.form.cbdpId = null;
      this.form.coprId = null;
      this.form.cocsId = null;
      this.form.cocoId = null;
      this.datosVaciarPaciente =null;
    },
    async searchPaciente() {
      this.isLoading = true;
      if (this.searchP.length > 0 && this.searchP.toString().replace(/ /g, "") != "") {
        this.$apiService.index("sistema/personas/buscar2/pacientes/" + this.searchP.toUpperCase()).then(data => {
          this.isLoading = false;
          this.pacientes = data;
          this.guardopaciente = false;
        });
      }
    },
    async cargarEps() {
      this.$apiService.index("cita/basedatospacientes/index/" + this.form.coppId + "/" + new Date().yyyymmdd()).then(data => {
        if (data.length > 0) {
          this.bd = data;
        } else {
          this.bd = [];
        }

     });
        },
        async vaciarpaciente(respuesta) {
            if (respuesta == null) {
                this.cambio = null;
                this.datosVaciarPaciente = null
                this.$nextTick().then(() => {
                    if (this.form.coppId) {
                        this.cambio = this.pacientes.filter(e => e.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId == this.form.coppId)[0].copeId;
                        this.pacienteactual = this.pacientes.filter(e => e.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId == this.form.coppId)[0];
                        this.datosVaciarPaciente = {}
                        this.datosVaciarPaciente.copeId =this.cambio;
                        this.datosVaciarPaciente.nuevo = this.nuevo;
                        // console.log(((new Date().getTime() - new Date(this.pacienteactual.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000));
                    }
                });
            } else {
                if (respuesta.tipo == 'nuevo') {
                    this.pacientes.push(respuesta.paciente);
                    this.form.coppId = respuesta.paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId;
                    this.guardopaciente = true;
                    this.datosVaciarPaciente = null
                } else {
                    this.guardopaciente = true;
                    this.cambio = null;
                    this.datosVaciarPaciente = null
                    this.$nextTick().then(() => {
                        if (this.form.coppId) {
                            this.cambio = this.pacientes.filter(e => e.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId == this.form.coppId)[0].copeId;
                            this.pacienteactual = this.pacientes.filter(e => e.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId == this.form.coppId)[0];
                            this.datosVaciarPaciente = {}
                            this.datosVaciarPaciente.copeId =this.cambio;
                            this.datosVaciarPaciente.nuevo = this.nuevo;
                            // console.log(((new Date().getTime() - new Date(this.pacienteactual.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000));
                        }
                    });
                }
            }

      if (this.form.coppId) {
        await this.cargarEps();
        await this.getServicios();
      }
    },
    async search(filtros) {
            this.lista = this.listaCopia = [];
            this.loadingTable = true;
            if(this.coppId){
              filtros[2].value = this.coppId;
            }
            const data = await this.$apiService.index(`cita/citas/buscar/0?${this.filtrar(filtros,this.page)}`).then(data => data);
            if (data) {
                this.lista = this.listaCopia = data.rows;
                this.Count = data.count;
                this.pageCount = Math.ceil((data.count) / 10);
            }
            this.loadingTable = false;
        },
    async getEmpleados() {
      if (this.form.cocsId) {
        let servicio = this.servicios.find(e => e.cocsId == this.form.cocsId);
        this.empleados = await this.$apiService.index( `sistema/Personasempleadosservicios/empleados/${servicio.coseId}/${servicio.cupsId}/${servicio.coseTipoServicio}`).then(data => data);
        this.empleados = this.empleados.filter(e => e.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].estado.comaNombrecorto == 'ACTIVO');
        this.empleados = this.empleados.filter(e => !e.tblConfPersonasnaturales[0].tblConfPersonasempleados[0].permisos.some(a => ((new Date(a.cpepFechainicio).getTime() <= new Date().getTime()) && (new Date(a.cpepFechafinal).getTime() >= new Date().getTime()))));
      }
    },
    async getServicios() {
      if (this.form.cocoId) {
        let data = await this.$apiService.index("sistema/contratosxservicios/contrato/" + this.form.cocoId).then(data => data);
        this.servicios = data;
        this.servicios = this.servicios.filter(e =>
          (e.tblConfServicios[0].coseSexo == 0 || e.tblConfServicios[0].coseSexo == ((this.pacienteactual.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].sexo.comaNombrecorto == 'M') ? 1 : 2)) &&
          ((new Date().getTime() - new Date(this.pacienteactual.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000) >= e.tblConfServicios[0].coseEdadinicio

          &&
          ((new Date().getTime() - new Date(this.pacienteactual.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000) <= e.tblConfServicios[0].coseEdadfinal)

      } else {
        if (this.bd.length > 0) {
          if (this.bd.find(e => e.cbdpId == this.form.cbdpId)) {
            let data = await this.$apiService.index("sistema/contratosxservicios/contrato/" + this.bd.find(e => e.cbdpId == this.form.cbdpId).cocoId).then(data => data);
            this.servicios = this.servicios.concat(data);

            this.servicios = this.servicios.filter(e =>
              (e.tblConfServicios[0].coseSexo == 0 || e.tblConfServicios[0].coseSexo == ((this.pacienteactual.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].sexo.comaNombrecorto == 'M') ? 1 : 2)) &&
              ((new Date().getTime() - new Date(this.pacienteactual.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000) >= e.tblConfServicios[0].coseEdadinicio

              &&
              ((new Date().getTime() - new Date(this.pacienteactual.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000) <= e.tblConfServicios[0].coseEdadfinal)

          }
        }
      }

    },
    addMinutes(date, minutes) {
      return new Date(date.getTime() + minutes * 60000);
    },
    getTime(hora) {
      return (parseInt(hora.split(':')[0]) - ((parseInt(hora.split(':')[0]) > 12) ? 12 : 0)) + ':' + (hora.split(':')[1].length > 1 ? hora.split(':')[1] : '0' + hora.split(':')[1]) + ((parseInt(hora.split(':')[0]) > 11) ? ' PM' : ' AM')
    },
    generarHoras(horas, fechaIni, fechaFin, interval, cantidadLimite, cantidades) {
      let inicial = new Date('2021/01/01 ' + fechaIni);
      let actual = new Date('2021/01/01 ' + fechaIni);
      let final = new Date('2021/01/01 ' + fechaFin);
      let hora = this.getTime(inicial.getHours() + ':' + inicial.getMinutes());
      let cantidad = 0;
      if (cantidades.find(e => e.cita_hora == hora)) {
        cantidad = cantidades.find(e => e.cita_hora == hora).cantidad;
      }
      if (cantidadLimite > cantidad && (cantidadLimite - cantidad) > 0) {
        horas.push({
          horaCantidad: hora + ' - turnos disponibles ' + (cantidadLimite - cantidad),
          hora: hora
        });
      }
      while (actual.getTime() <= final.getTime()) {
        hora = this.getTime(actual.getHours() + ':' + actual.getMinutes());
        cantidad = 0;
        if (cantidades.find(e => e.cita_hora == hora)) {
          cantidad = cantidades.find(e => e.cita_hora == hora).cantidad;
        }
        if (cantidadLimite > cantidad && (cantidadLimite - cantidad) > 0) {
          horas.push({
            horaCantidad: hora + ' - turnos disponibles ' + (cantidadLimite - cantidad),
            hora: hora
          });
        }
        actual = this.addMinutes(actual, interval);
      }
      return horas;
    },
    async disponibilidad() {
      let cantidades = await this.$apiService.index(`cita/disponibilidad/horas/${this.form.coprId}/${this.form.citaFecha }`).then(data => data);
      this.dispo = await this.$apiService.index(`cita/disponibilidad/horarios/${this.form.coprId}/${this.form.citaFecha }`).then(data => data);
      if (this.dispo.length > 0) {
        this.tipohoras = [];
        this.dispo.sort((a, b) => new Date('2021/01/01 ' + a.cpeh_hora_inicio).getTime() - new Date('2021/01/01 ' + b.cpeh_hora_inicio).getTime());
        for (const turno of this.dispo) {
          this.tipohoras = this.generarHoras(this.tipohoras, turno.cpeh_hora_inicio, turno.cpeh_hora_final, turno.cpeh_turnos_hora, turno.cant_turnos, cantidades);
        }
      } else {
        this.dispo = [];
        this.tipohoras = [];

      }
      /*if (this.form.citaTipoJornada) {
          this.dispo = await this.$apiService.index(`cita/disponibilidad/turnos2/${this.form.coprId}/${this.form.citaFecha }/${this.form.citaTipoJornada}`).then(data => data);
          if (this.dispo.length > 0) {
              this.tipoturno = [];
              for (const turno of this.dispo) {
                  this.tipoturno.push(turno);
              }
          } else {
              this.dispo = [];
              this.tipoturno = [];

          }
      }*/

    },
    async validarHoras(){
      await this.disponibilidad();
      return this.tipohoras.some(e => e.hora ==this.form.citaHora );
    },
    async agregar() {
      this.$v.form.$touch();
      
      if (!this.$v.form.$error) {
        if(!await  this.validarHoras()){
          this.$swal.fire({
            title: "Turno ya no disponible",
            text: "por favor escoja otra hora",
            icon: "error"
          });
            return true;
        }
        this.loadingCita = true;
        try {
          if (this.isNew) {
              let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);

              if (data) {
                await this.search(this.filtros);
                this.form.citaTipoJornada = null;
                this.form.citaTipoTurno = null;
                this.form.coprId = null;
                this.form.cocsId = null;
                this.form.cocoId = null;

              }
          } else {
              var id = this.form.citaId;
              let data = await this.$apiService.update(this.url + "update/" + id + "/", this.form).then(data => data);

              if (data) {
                this.lista = this.lista.filter(e => e.citaId != id);
                this.listaCopia = this.lista.push(data);
                this.$swal.fire({
                  title: "Completado!",
                  text: "Registro exitoso",
                  icon: "success",
                  confirmButtonText: "Ok"
                });

              }
            this.close();
          }
        } catch (error) {
          console.log(error);
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }
        this.loadingCita = false;
      }
    },
    async cancelar(id) {
      try {
          let data = await this.$apiService.update(this.url + "cancelar/" + id + "/", null).then(data => data);

          if (data) {
            this.lista = this.lista.filter(e => e.citaId != id);
            this.listaCopia = this.lista.push(data);
            this.$swal.fire({
              title: "Completado!",
              text: "Cita cancelada",
              icon: "success",
              confirmButtonText: "Ok"
            });

          }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async eliminar(id) {
      try {
          var result = await this.$swal.fire({
            title: "Estas seguro?",
            text: "No podras revertir esto!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Si, eliminar!"
          }).then(result => result.value);
          if (result) {
            let data = await this.$apiService.delete(this.url + "delete/" + id + "/").then(data => data);
            if (data) {
              await this.search(this.filtros);
              this.$swal.fire(
                "Eliminado!",
                "ha sido eliminado con exito.",
                "success"
              );
            }
          }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async editar(id) {
      try {
        this.form = await this.$apiService.index(this.url + "view/" + id).then(data => data);
        this.isNew = false;
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    ingresar(item) {
            try {
                this.ingreso = null;
                this.$nextTick().then(() => {
                    this.ingreso = {item:item};
                });
                this.dialog3 = true;
            } catch (error) {
                console.log(error)
                this.$swal.fire({
                    title: "Error!",
                    text: "contacte con el administrador",
                    icon: "error"
                });
            }
    },
    async dibujarH(item,historico = false) {
            try {
                this.pdfObj = null;
                this.$nextTick().then(() => {
                    item.historico = historico;
                    this.pdfObj = {item:item};
                });
                this.dialog2 = true;
            } catch (error) {
                this.loading = false;
                console.log(error)
                this.$swal.fire({
                    title: "Error!",
                    text: "contacte con el administrador",
                    icon: "error"
                });
            }
    },
    async enviar(item) {
        try {
              this.pdfObj = item;
              this.correo = item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeEmail
              this.dialog4= true;
        } catch (error) {
            console.log(error)
            this.$swal.fire({
              title: "Algo sucedio!",
              text: "contacta con el administrador",
              icon: "error"
          });
        }
    },
    async enviarRecordatorio() {
        try {
              if (!this.correo ) {
                  this.$swal.fire({
                      title: "Correo invalido!",
                      text: "ingrese un correo valido",
                      icon: "error"
                  });
                  return false;
              }
              if (this.correo.indexOf('@') < 0) {
                  this.$swal.fire({
                      title: "Correo invalido!",
                      text: "ingrese un correo valido",
                      icon: "error"
                  });
                  return false;
              }
              this.loadingEmail = true;
              let resultado = await this.$apiService.create("cita/citas/sendrecordatorio?correo=" + this.correo, this.pdfObj).then(data => data);
              if (resultado == 'Correo enviado correctamente') {
                  this.$swal.fire({
                      title: "Completado!",
                      text: "Envio exitoso",
                      icon: "success",
                      confirmButtonText: "Ok"
                  });
              } else {
                  this.$swal.fire({
                      title: "Algo sucedio!",
                      text: "contacta con el administrador",
                      icon: "error"
                  });
              }
              
        } catch (error) {
            this.loadingEmail = false;
            console.log(error)
            this.$swal.fire({
              title: "Algo sucedio!",
              text: "contacta con el administrador",
              icon: "error"
          });
        }
            
      this.loadingEmail = false;
    },
    
    async pdfDiagnosticos(item) {
      this.loading = true;
      var datosPaciente = await this.$apiService.index(this.url + "view/factura/" + item.citaId).then(data => data);
      this.diagnosticos = [];

      this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/diagnostico").then(data => data);
      if (this.diagnosticos.length) {
        let StrucsDataPaciente = await this.$apiService.index("sistema/maestra/index/TBL_DATOSPACIENTE").then(data => data);
        let StrucDataPaciente =  StrucsDataPaciente.find(e => e.comaNombrecorto == 'DP_DIAGNOSTICOS').comaValor;
        datosPaciente.StrucDataPaciente = StrucDataPaciente;
        let tipoconfig = await this.$apiService.index("sistema/maestra/index/TBL_TIPOCOFIG",this.token).then(data => data);
       let confOrdenes = '{}'; 
        if(tipoconfig.some(e => e.comaNombrecorto == 'TBL_CONFORDENES')){
          confOrdenes = tipoconfig.find(e => e.comaNombrecorto == 'TBL_CONFORDENES').comaValor;
        }
        datosPaciente.confOrdenes = confOrdenes;
        if (typeof window !== 'undefined') {
          let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("ENTIDAD"));
          let pdf = new jsPDF();
          documento.getDiagnosticos(pdf, this.diagnosticos, false, datosPaciente, 2);
        }
        this.loading = false;
      } else {
        this.loading = false;
        this.$swal.fire({
          title: "No hay diagnosticos tramitadas!",
          icon: "error"
        });
      }
    },
    async pdfRecetarios(item) {
      this.diagnosticos = [];
      this.loading = true;

      this.recetarios = [];
      var datosPaciente = await this.$apiService.index(this.url + "view/factura/" + item.citaId).then(data => data);
      console.log()
      let data = await this.$apiService.index(`sistema/personas/getFirmaPaciente?copeIdentificacion=${datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeId}`).then(data => data);
      if (data.mensaje == undefined && data.mensaje != 'No data') {
        datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = 'data:image/png;base64,' + data;
      } else {
        datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = '/' + datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma
      }
      this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/diagnostico").then(data => data);

      this.recetarios = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/recetario").then(data => data);
      if (this.recetarios.length) {
        let StrucsDataPaciente = await this.$apiService.index("sistema/maestra/index/TBL_DATOSPACIENTE").then(data => data);
        let StrucDataPaciente =  StrucsDataPaciente.find(e => e.comaNombrecorto == 'DP_RECETARIO').comaValor;
        datosPaciente.StrucDataPaciente = StrucDataPaciente;
        let tipoconfig = await this.$apiService.index("sistema/maestra/index/TBL_TIPOCOFIG",this.token).then(data => data);
       let confOrdenes = '{}'; 
        if(tipoconfig.some(e => e.comaNombrecorto == 'TBL_CONFORDENES')){
          confOrdenes = tipoconfig.find(e => e.comaNombrecorto == 'TBL_CONFORDENES').comaValor;
        }
        datosPaciente.confOrdenes = confOrdenes;
        if (typeof window !== 'undefined') {
          let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("ENTIDAD"));

          let pdf = new jsPDF();
          documento.getRecetarios(pdf, this.recetarios, false, datosPaciente, 2, this.diagnosticos);
        }
        this.loading = false;
      } else {
        this.loading = false;
        this.$swal.fire({
          title: "No hay recetarios tramitadas!",
          icon: "error"
        });
      }
    },

    async pdfOrdenes(item,tipo) {
      this.loading = true;
      this.diagnosticos = [];

      this.ordenes = [];
      var datosPaciente = await this.$apiService.index(this.url + "view/factura/" + item.citaId).then(data => data);
      this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/diagnostico").then(data => data);

      this.ordenes = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/orden?tipo=AUTORIZACION").then(data => data);
      let data = await this.$apiService.index(`sistema/personas/getFirmaPaciente?copeIdentificacion=${datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeId}`).then(data => data);
      if (data.mensaje == undefined && data.mensaje != 'No data') {
        datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = 'data:image/png;base64,' + data;
      } else {
        datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = '/' + datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma
      }
      if (this.ordenes.length) {
        let StrucsDataPaciente = await this.$apiService.index("sistema/maestra/index/TBL_DATOSPACIENTE").then(data => data);
        let StrucDataPaciente =  StrucsDataPaciente.find(e => e.comaNombrecorto == 'DP_RECETARIO').comaValor;
        datosPaciente.StrucDataPaciente = StrucDataPaciente;
        let tipoconfig = await this.$apiService.index("sistema/maestra/index/TBL_TIPOCOFIG",this.token).then(data => data);
       let confOrdenes = '{}'; 
        if(tipoconfig.some(e => e.comaNombrecorto == 'TBL_CONFORDENES')){
          confOrdenes = tipoconfig.find(e => e.comaNombrecorto == 'TBL_CONFORDENES').comaValor;
        }
        datosPaciente.confOrdenes = confOrdenes;
        if (typeof window !== 'undefined') {
          let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("ENTIDAD"));

          let pdf = new jsPDF();
          switch (tipo) {
            case "LAB":
            documento.getOrdenesLaboratorios(pdf, this.ordenes, false, datosPaciente, 2, this.diagnosticos);
              break;
              case "IMGDX":
            documento.getOrdenesImagenDiag(pdf, this.ordenes, false, datosPaciente, 2, this.diagnosticos);
              break;
              case "SERV":
            documento.getOrdenesServicios(pdf, this.ordenes, false, datosPaciente, 2, this.diagnosticos);
              break;
              case "CIR":
            documento.getOrdenesCirujia(pdf, this.ordenes, false, datosPaciente, 2, this.diagnosticos);
              break;
          }
        }
        this.loading = false;
      } else {
        this.loading = false;
        this.$swal.fire({
          title: "No hay ordenes tramitadas!",
          icon: "error"
        });
      }
    },
    async pdfRemiciones(item) {
      this.diagnosticos = [];
      this.loading = true;

      this.remisiones = [];
      var datosPaciente = await this.$apiService.index(this.url + "view/factura/" + item.citaId).then(data => data);
      this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/diagnostico").then(data => data);
      let data = await this.$apiService.index(`sistema/personas/getFirmaPaciente?copeIdentificacion=${datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeId}`).then(data => data);
      if (data.mensaje == undefined && data.mensaje != 'No data') {
        datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = 'data:image/png;base64,' + data;
      } else {
        datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = '/' + datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma
      }
      this.remisiones = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/remision").then(data => data);
      if (this.remisiones.length) {
        let StrucsDataPaciente = await this.$apiService.index("sistema/maestra/index/TBL_DATOSPACIENTE").then(data => data);
        let StrucDataPaciente =  StrucsDataPaciente.find(e => e.comaNombrecorto == 'DP_RECETARIO').comaValor;
        datosPaciente.StrucDataPaciente = StrucDataPaciente;
        let tipoconfig = await this.$apiService.index("sistema/maestra/index/TBL_TIPOCOFIG",this.token).then(data => data);
       let confOrdenes = '{}'; 
        if(tipoconfig.some(e => e.comaNombrecorto == 'TBL_CONFORDENES')){
          confOrdenes = tipoconfig.find(e => e.comaNombrecorto == 'TBL_CONFORDENES').comaValor;
        }
        datosPaciente.confOrdenes = confOrdenes;
        if (typeof window !== 'undefined') {
          let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("ENTIDAD"));

          let pdf = new jsPDF();
          documento.getRemisiones(pdf, this.remisiones, false, datosPaciente, 2, this.diagnosticos);
        }
        this.loading = false;
      } else {
        this.loading = false;
        this.$swal.fire({
          title: "No hay remisiones tramitadas!",
          icon: "error"
        });
      }
    },
    async pdfIncapacidad(item) {
      this.diagnosticos = [];

      this.incapacidades = [];
      var datosPaciente = await this.$apiService.index(this.url + "view/factura/" + item.citaId).then(data => data);
      this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/diagnostico").then(data => data);
      let data = await this.$apiService.index(`sistema/personas/getFirmaPaciente?copeIdentificacion=${datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeId}`).then(data => data);
      if (data.mensaje == undefined && data.mensaje != 'No data') {
        datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = 'data:image/png;base64,' + data;
      } else {
        datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = '/' + datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma
      }
      this.incapacidades = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/incapacidadcertificado?tipo=INCAPACIDAD").then(data => data);
      if (this.incapacidades.length) {
        let StrucsDataPaciente = await this.$apiService.index("sistema/maestra/index/TBL_DATOSPACIENTE").then(data => data);
        let StrucDataPaciente =  StrucsDataPaciente.find(e => e.comaNombrecorto == 'DP_RECETARIO').comaValor;
        datosPaciente.StrucDataPaciente = StrucDataPaciente;
        let tipoconfig = await this.$apiService.index("sistema/maestra/index/TBL_TIPOCOFIG",this.token).then(data => data);
       let confOrdenes = '{}'; 
        if(tipoconfig.some(e => e.comaNombrecorto == 'TBL_CONFORDENES')){
          confOrdenes = tipoconfig.find(e => e.comaNombrecorto == 'TBL_CONFORDENES').comaValor;
        }
        datosPaciente.confOrdenes = confOrdenes;
        if (typeof window !== 'undefined') {
          let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("ENTIDAD"));

          let pdf = new jsPDF();
          documento.getIncapacidades(pdf, this.incapacidades, false, datosPaciente, 2, this.diagnosticos);
        }
      } else {
        this.$swal.fire({
          title: "No hay incapacidades tramitadas!",
          icon: "error"
        });
      }
    },
    async pdfCertificado(item) {
      this.diagnosticos = [];

      this.certificados = [];
      var datosPaciente = await this.$apiService.index(this.url + "view/factura/" + item.citaId).then(data => data);
      this.diagnosticos = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/diagnostico").then(data => data);
      let data = await this.$apiService.index(`sistema/personas/getFirmaPaciente?copeIdentificacion=${datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeId}`).then(data => data);
      if (data.mensaje == undefined && data.mensaje != 'No data') {
        datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = 'data:image/png;base64,' + data;
      } else {
        datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma = '/' + datosPaciente.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeFirma
      }
      this.certificados = await this.$apiService.index("cita/asistenciales/atender/index/" + datosPaciente.tblCitaFacturaciones[0].cifaId + "/incapacidadcertificado?tipo=CERTIFICADO").then(data => data);
      if (this.certificados.length) {
        let StrucsDataPaciente = await this.$apiService.index("sistema/maestra/index/TBL_DATOSPACIENTE").then(data => data);
        let StrucDataPaciente =  StrucsDataPaciente.find(e => e.comaNombrecorto == 'DP_RECETARIO').comaValor;
        datosPaciente.StrucDataPaciente = StrucDataPaciente;
        let tipoconfig = await this.$apiService.index("sistema/maestra/index/TBL_TIPOCOFIG",this.token).then(data => data);
       let confOrdenes = '{}'; 
        if(tipoconfig.some(e => e.comaNombrecorto == 'TBL_CONFORDENES')){
          confOrdenes = tipoconfig.find(e => e.comaNombrecorto == 'TBL_CONFORDENES').comaValor;
        }
        datosPaciente.confOrdenes = confOrdenes;
        if (typeof window !== 'undefined') {
          let documento = new documentos(this.$config.listaIPS,this.$config.convenciones,null, this.$cookies.get("ENTIDAD"));

          let pdf = new jsPDF();
          documento.getCertificados(pdf, this.certificados, false, datosPaciente, 2, this.diagnosticos);
        }
      } else {
        this.$swal.fire({
          title: "No hay certificados tramitadas!",
          icon: "error"
        });
      }
    },
    async exporttabla() {
      this.LoadingExp = true;
      var search = `offset=${(this.page-1)*10}&`;

      if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
        search = search + `citaFecha=${this.filtros[0].value}&`;
      }

      if (this.filtros[1].value != null && this.filtros[1].value.toString().replace(/ /g, "") != "") {
        search = search + `citaTipoEstado=${this.filtros[1].value}&`;

      }
      if (this.filtros[2].value != null && this.filtros[2].value.toString().replace(/ /g, "") != "") {
        search = search + `coppId=${this.filtros[2].value}&`;

      }
      if (this.filtros[3].value != null && this.filtros[3].value.toString().replace(/ /g, "") != "") {
        search = search + `coprId=${this.filtros[3].value}&`;

      }
      if (this.filtros[4].value != null && this.filtros[4].value.toString().replace(/ /g, "") != "") {
        search = search + `coseId=${this.filtros[4].value}&`;

      }
      if (this.filtros[5].value != null && this.filtros[5].value.length > 1) {
        search = search + `citaFecharango=${this.filtros[5].value[0]},${this.filtros[5].value[1]}&`;
      }
      if (this.filtros[6].value != null && this.filtros[6].value.length > 1) {
        search = search + `admisionista=${this.filtros[6].value}&`;
        this.filtros[6].value = [];
      }
      if (this.filtros[7].value != null && this.filtros[7].value.toString().replace(/ /g, "") != "") {
        search = search + `coemId=${this.filtros[7].value}&`;
      }
      try {
        let citas = await this.$apiService.index(`cita/citas/buscarcompleto/0?${search}`).then(data => data);
        import('@/vendor/Export2Excel').then(excel => {
          let data =[{sheet:"Page1",data: []}];
          let head = [{sheet:"Page1",data:['FECHA','HORA', 'TURNO', 'ESTADO', 'PACIENTE', 'PACIENTE_IDENTIFICACION', 'EDAD', 'EMPLEADO', 'EMPLEADO_IDENTIFICACION', 'EPS', 'CONTRATO', 'SERVICIO','CUPS','CUPS DESC', 'TELEFONO']}];
          
          for (let item of citas) {

            data[0].data.push([
              item.citaFecha,
              item.citaHora,
              ((item.turno != null) ? item.turno.comaNombrelargo : '') + " - " + ((item.jornada != null) ? item.jornada.comaNombrelargo : ''),
              (item.estado != null) ? item.estado.comaNombrecorto : '',
              (item.tblConfPersonaspacientes.length > 0) ? item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1 + " " + item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1 : '',
              (item.tblConfPersonaspacientes.length > 0) ? item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion : '',
              new Date().calcularEdad(item.tblConfPersonaspacientes[0].coppFechanacimiento),
              (item.tblConfPersonasempleados.length > 0) ? item.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 + " " + item.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1 : '',
              (item.tblConfPersonasempleados.length > 0) ? item.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion : '',
              (item.tblConfBasedatospacientes.length > 0) ? item.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemNombre : (item.tblConfContrato) ? item.tblConfContrato.tblConfPersonasempresas[0].coemNombre : '',
              (item.tblConfBasedatospacientes.length > 0) ? item.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].cocoNombre : (item.tblConfContrato) ? item.tblConfContrato.cocoNombre : '',

              item.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre,
              item.tblConfContratosxservicios[0].tblConfCup.cocuCups,
              item.tblConfContratosxservicios[0].tblConfCup.cocuNombre,
              item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono
            ]);
          }
          excel.export_json_to_excel({
            header: head, //Header Required
            data, //Specific data Required
            filename: 'excel-list', //Optional
            autoWidth: true, //Optional
            bookType: 'xlsx' //Optional
          })
        });
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

      this.LoadingExp = false;

    },
    async pdf(item ,historico = false) {
      if(this.getPermiso("verCitas")){
        await this.dibujarH(item,historico);
      }
    },
    limpiar() {
      this.form = {
        citaFecha: this.currentDate(new Date()),
        citaTipoJornada: null,
        citaTipoTurno: null,
        citaTipoSala: null,
        citaTipoEstado: null,
        citaTipo: 0,
        coppId: null,
        createdBy: this.user.id,
        coprId: null,
        cbdpId: null,
        cocsId: null,
        cocoId: null,
        citaTipoDetalle:null
      };
    },
    close() {
      this.cambio = null;
      this.guardopaciente = false;
      this.pacienteactual = null;
      this.isNew = true;
      this.dialog = false;
      this.datosVaciarPaciente = null;
      this.limpiar();
    }
  }
};

</script>

<style>

</style>
