<style>
</style>
<template>
<v-container lighten-5>
    <v-row>
        <v-col cols="12" sm="12" md="12">
            <span class="mx-2 secondary--text display-2 float-left">Laboratorios</span>
        </v-col>
        <v-col cols="12" sm="12" md="12">
            <v-btn class="mx-2 float-right" fab small dark color="accent" @click="verFiltros=!verFiltros">
                <v-icon dark>filter_list</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="12" sm="12" md="12" v-if="tabla">
          <v-card elevation="0" class="rounded-xl">
              <v-data-iterator :items="lista" :page.sync="page" :loading="loadingTable" :item-key="tabla.itemKey" :items-per-page="10" single-expand hide-default-footer :sort-by="tabla.orderBy">
                  <template v-slot:header>
                      <Dfiltros v-if="verFiltros" :onBuscar="search" v-bind:filtros="filtros" />
                      <v-toolbar dark class="mb-1">
                          <v-btn v-if="!coppId" class="mx-2 primary--text" tile color="white" @click.stop="dialog = true">
                              agregar
                          </v-btn>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <div class="flex-grow-1"></div>
                      </v-toolbar>
                  </template>
                  <template v-slot:default="{ items, isExpanded, expand }">
                       <CardTable v-bind:table="{ tabla, data:{ items, isExpanded, expand }}"></CardTable>
                  </template>
                  <template v-slot:no-data>
                      <v-row class="mt-2" align="center" justify="center">
                          <v-col cols="10" sm="10" md="10" lg="10">
                              <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                  No se encontro nada.
                              </v-alert>
                          </v-col>
                      </v-row>
                  </template>
                  <template v-slot:loading>
                      <v-row class="mt-2" align="center" justify="center">
                          <v-col cols="12" sm="12" md="12" lg="12">
                              <Loader />
                          </v-col>
                      </v-row>
                  </template>
                  <template v-slot:footer>
                      <Paginacion v-on:cambiarPagina="page = $event" :onSearch="search" v-bind:propiedades="{filtros,page,pageCount,Count}" />
                  </template>
              </v-data-iterator>
          </v-card>
      </v-col>
      <v-form @submit.prevent="agregar">
          <v-dialog v-model="dialog" persistent min-width="1200px" width="1200px">
              <v-card>
                  <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                      <v-container>
                          <template v-if="form.coppId && tipo == 'CAPITADO'">
                              <v-alert text prominent dense type="success" v-if="bd.length > 0">
                                  El paciente se encuentra en las base de datos de <template v-for="b in bd"> {{ ','+ b.cocoNombre  }}</template>
                              </v-alert>
                              <v-alert text prominent dense type="error" v-else>
                                  El paciente no se encuentra en ninguna base de datos para el mes de {{ meses[ new Date().getMonth()]}}.
                              </v-alert>
                          </template>
                          <v-row align="center">
                              <v-col cols="12" sm="12" md="1" v-if="tipo=='EVENTO'">
                                  <template v-if="!nuevo">

                                      <v-icon color="success" style="margin-bottom: 20px;margin-left: 50px;" @click="nuevo=!nuevo;">mdi-account-plus</v-icon>

                                  </template>
                                  <template v-else>

                                      <v-icon color="primary" style="margin-bottom: 20px;margin-left: 50px;" @click="nuevo=!nuevo;">mdi-account-search</v-icon>

                                  </template>

                              </v-col>
                              <v-col cols="12" sm="12" md="9">

                                  <v-autocomplete outlined :rules="nameRules" :error="$v.form.coppId.$error" v-model="form.coppId" :items="pacientes" :loading="isLoading" :search-input.sync="searchP" @keypress.enter="searchPaciente" @change="vaciarpaciente(null)" no-filter hide-no-data hide-selected item-text="tblConfPersonasnaturales[0].copnNombre1" item-value="tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId" item-color="primary" label="Paciente" clearable>
                                      <template v-slot:selection="data">
                                          {{data.item.tblConfPersonasnaturales[0].copnNombre1 + " "+data.item.tblConfPersonasnaturales[0].copnNombre2 + " "+data.item.tblConfPersonasnaturales[0].copnApellido1 + " "+data.item.tblConfPersonasnaturales[0].copnApellido2}}
                                      </template>
                                      <template v-slot:item="data">
                                          <template v-if="(typeof data.item !== 'object')">
                                              <v-list-item-content v-text="data.item"></v-list-item-content>
                                          </template>
                                          <template v-else>
                                              <v-list-item-content>
                                                  <v-list-item-title v-html="data.item.tblConfPersonasnaturales[0].copnNombre1 + ' '+data.item.tblConfPersonasnaturales[0].copnNombre2 + ' '+data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' +data.item.tblConfPersonasnaturales[0].copnApellido2"></v-list-item-title>
                                                  <v-list-item-subtitle v-html="data.item.copeIdentificacion">

                                                  </v-list-item-subtitle>
                                              </v-list-item-content>
                                          </template>
                                      </template>
                                  </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="12" md="2">
                                  <v-switch v-model="tipo" true-value="EVENTO" false-value="CAPITADO" :label="tipo"></v-switch>
                              </v-col>
                              <v-col cols="12" sm="12" md="4" v-if="tipo=='EVENTO' && guardopaciente">
                                  <v-autocomplete outlined v-model="form.cocoId" :items="contratos" item-text="cocoNombre" item-value="cocoId" item-color="primary" label="Contrato" clearable>
                                      <template v-slot:selection="data">
                                          {{data.item.cocoNombre +" - "+data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}
                                      </template>
                                      <template v-slot:item="data">
                                          <template v-if="(typeof data.item !== 'object')">
                                              <v-list-item-content v-text="data.item"></v-list-item-content>
                                          </template>
                                          <template v-else>
                                              <v-list-item-content>
                                                  <v-list-item-title>{{ data.item.cocoNombre}}</v-list-item-title>
                                                  <v-list-item-subtitle> {{data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo}}</v-list-item-subtitle>
                                              </v-list-item-content>
                                          </template>
                                      </template>
                                  </v-autocomplete>
                              </v-col>

                              <v-col cols="12" sm="12" md="4" v-else-if="bd.length > 0 && guardopaciente">
                                  <v-autocomplete outlined v-model="form.cbdpId" :items="bd" item-text="cocoNombre" item-value="cbdpId" item-color="primary" label="EPS contrada" clearable>
                                      <template v-slot:item="data">
                                          <template v-if="(typeof data.item !== 'object')">
                                              <v-list-item-content v-text="data.item"></v-list-item-content>
                                          </template>
                                          <template v-else>
                                              <v-list-item-content>
                                                  <v-list-item-title>{{data.item.cocoNombre}}</v-list-item-title>
                                                  <v-list-item-subtitle> {{data.item.coemNombre}}</v-list-item-subtitle>
                                              </v-list-item-content>
                                          </template>
                                      </template>
                                  </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="12" md="4" v-if=" form.coppId && guardopaciente">
                                  <v-autocomplete outlined :error="$v.form.coprId.$error" required v-model="form.coprId" :items="empleados" :filter="(item, queryText, itemText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' '+item.tblConfPersonasnaturales[0].copnNombre2 + ' '+item.tblConfPersonasnaturales[0].copnApellido1 + ' '+item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1 " item-text="tblConfPersonasnaturales[0].copnNombre1" item-value="tblConfPersonasnaturales[0].tblConfPersonasempleados[0].coprId" item-color="primary" label="Encargado del procedimiento" clearable>
                                      <template v-slot:selection="data">
                                          {{data.item.tblConfPersonasnaturales[0].copnNombre1 + " "+data.item.tblConfPersonasnaturales[0].copnNombre2 + " "+data.item.tblConfPersonasnaturales[0].copnApellido1 + " "+data.item.tblConfPersonasnaturales[0].copnApellido2}}
                                      </template>
                                      <template v-slot:item="data">
                                          <template v-if="(typeof data.item !== 'object')">
                                              <v-list-item-content v-text="data.item"></v-list-item-content>
                                          </template>
                                          <template v-else>
                                              <v-list-item-content>
                                                  <v-list-item-title v-html="data.item.tblConfPersonasnaturales[0].copnNombre1 + ' '+data.item.tblConfPersonasnaturales[0].copnNombre2 + ' '+data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' +data.item.tblConfPersonasnaturales[0].copnApellido2"></v-list-item-title>
                                                  <v-list-item-subtitle v-html="data.item.copeIdentificacion">

                                                  </v-list-item-subtitle>
                                              </v-list-item-content>
                                          </template>
                                      </template>
                                  </v-autocomplete>

                              </v-col>
                              <v-col cols="12" sm="12" md="3" v-if="form.coppId && guardopaciente">
                                  <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                      <template v-slot:activator="{ on }">
                                          <v-text-field :rules="nameRules" required outlined :error="$v.form.citaFecha.$error" v-model="form.citaFecha" label="Fecha de la cita" prepend-icon="event" readonly v-on="on"></v-text-field>
                                      </template>
                                      <v-date-picker locale="ES" :min="(user.role.indexOf('ADMIN') >-1)?'2000-01-01':new Date().yyyymmdd()" :allowed-dates="allowedDates" v-model="form.citaFecha" @input="menu = false"></v-date-picker>
                                  </v-menu>
                              </v-col>
                              <v-col cols="12" sm="12" md="3" v-if="form.coppId &&  guardopaciente">
                                  <v-autocomplete outlined :rules="nameRules" :error="$v.form.citaTipoEstado.$error" required v-model="form.citaTipoEstado" :items="tipoestado.filter(e=>e.comaNombrecorto == 'ACTIVA')" :input="tipoestado.find(e=>e.comaNombrecorto == 'ACTIVA').comaId" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Estado" clearable></v-autocomplete>
                              </v-col>

                          </v-row>

                      </v-container>
                      <template v-if="(cambio && !guardopaciente) || (nuevo && !guardopaciente)">
                          <Createpaciente v-on:validar="vaciarpaciente($event)" v-bind:datosVaciarPaciente="datosVaciarPaciente" />
                      </template>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                      <v-btn color="primary darken-1" dark @click="agregar" v-if="form.coppId">Guardar</v-btn>
                  </v-card-actions>
              </v-card>
          </v-dialog>
      </v-form>
      <v-dialog v-model="dialog3" width="1200px" max-width="1200px">
        <v-card>
          <v-card-title class="text-h5 primary">Ingresar</v-card-title>
          <v-card-text>
              <v-container>
                <v-row>
                    <v-col cols="12" sm="12" md="12">
                         <template v-if="ingreso">
                            <Facturaciones v-bind.sync="ingreso" />
                        </template>
                    </v-col>                   
                </v-row>              
              </v-container>                              
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog3 = false"> Cerrar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import generales from '../../mixins/generales.js';
import {
  required
}
from 'vuelidate/lib/validators'
export default {
  props:{
    coppId:Number,
  },
  components: {
    Createpaciente: () => import("../sistema/pacientes/Create"),
    Facturaciones: () => import("./Facturaciones.vue")
  },
   mixins: [generales],
  data() {
    return {
      guardopaciente: false,
      pacienteactual: null,
      nuevo: false,
      menu: false,
      tab: "tab-1",
      tipo: this.$cookies.get("DEFAULT"),
      ingreso:null,
      copeId: null,
      cambio: null,
      isLoading: false,
      meses: ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"],
      bd: [],
      searchP: null,
      url: "cita/citas/",
      datosVaciarPaciente: null,
      pacientes: [],
      cups: {
        search: null,
        isLoading: false,
        cups: []
      },
      tipoestado: [],
      paciente: null,
      form: {
        createdBy: this.$cookies.get("user").id,
        citaFecha: null,
        citaTipoEstado: null,
        citaTipo: 1,
        coppId: null,
        cbdpId: null,
        cocuId: null,
        cocoId: null
      },
      tabla: {
                itemKey: 'citaId',
                orderBy: 'citaFecha',
                expandible: false,
                expandibleKey: 'citaId',
                expandibleTitle: () => { return `` },
                expandibleItems: () => { return [] },
                primarios: [{
                        titulo: 'PACIENTE',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '12',
                            lg: '12'
                        },
                        class: 'primary--text',
                        valor: (item) => { return `${item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1} ${item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1}` },
                        show: (item) => { return item ? true : false }
                    }, {
                        titulo: 'FECHA',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'green--text',
                        valor: (item) => { return item.citaFecha },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'IDENT.',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'EDAD',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return this.calcularEdad(item.tblConfPersonaspacientes[0].coppFechanacimiento) },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'ESTADO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.estado.comaNombrecorto == 'FACTURADA'? 'INGRESADO': item.estado.comaNombrecorto },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'TELEFONO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '4',
                            lg: '4'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeTelefono },
                        show: (item) => { return item ? true : false }
                    },

                ],
                segundarios: [{
                        titulo: 'EPS',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '6',
                            lg: '6'
                        },
                        class: 'primary--text',
                        valor: (item) => { 
                           if(item.tblConfBasedatospacientes.length > 0){
                              return (item.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemPrincipal == 1)?'PARTICULAR':item.tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemNombre 
                            }else if(item.cocoId){
                              return (item.tblConfContrato.tblConfPersonasempresas[0].coemPrincipal == 1)?'PARTICULAR':item.tblConfContrato.tblConfPersonasempresas[0].coemNombre 
                            }
                        },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'ADMISIONISTA',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '6',
                            lg: '6'
                        },
                        class: 'primary--text',
                        valor: (item) => { return (item.admisionista) ? item.admisionista.username : '' },
                        show: (item) => { return item ? true : false }
                    },
                ],
                expanItem: [],
                expanMenu: [],
                menu: [
                  {
                        title: 'Ingresar',
                        icon: 'mdi-desktop-mac-dashboard',
                        color: 'primary',
                        tipeAction: 'funcion',
                        action: (item) => { return this.ingresar(item) },
                        show: (item) => { return item ? (item.estado.comaNombrecorto != 'CANCELADA') : false }
                    }, {
                        title: 'Cancelar',
                        icon: 'mdi-block-helper',
                        color: 'error',
                        tipeAction: 'funcion',
                        action: (item) => { return this.cancelar(item.citaId)},
                        show: (item) => { return item ? (item.estado.comaNombrecorto == 'ACTIVA') : false }
                    },
                    {
                        title: 'Eliminar',
                        icon: 'delete',
                        color: 'error',
                        tipeAction: 'funcion',
                        action: (item) => { this.eliminar(item.citaId) },
                        show: (item) => { return item ? this.getPermiso('eliminarMaestro') : false }
                    },
                ]
            },
       filtros: [{
          campo:"citaFecha",
          text: "Fecha",
          value: null,
          tipo: "fecha",
          menu: false,
          searchNormal: true,
          isLoading: false,
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
          listatext: "nombre del campo que se mostrara",
          listavalue: "nombre del valor que se mandara"*/
        },
        {
          campo:"citaTipoEstado",
          text: "Estado",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          searchNormal: true,
          isLoading: false,
          lista: [],
          listatext: "comaNombrelargo",
          listavalue: "comaId"
        }, {
          campo:"coppId",
          text: "Paciente",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          lista: [],
          listatext: "tblConfPersonasnaturales[0].copnNombre1",
          listavalue: "tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId",
          buscar: 'paciente',
          isLoading: false,
          search: null,
          //filter: (item, queryText, itemText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1,
          default: true,
          defaultselect: (data) => {
            return data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2
          },
          defaultbody: (data) => {
            return {
              title: data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2,
              subtitle: data.item.copeIdentificacion
            }
          },
        },
      ],
      empleados: [],
      contratos: [],
    };
  },
  validations: {
    form: {

      citaFecha: {
        required,
      },

      citaTipoEstado: {
        required,
      },

      coppId: {
        required,
      },
      coprId: {
        required,
      },
    },
  },
  computed: {

    formTitle() {
      return this.isNew === true ? "Nuevo" : "Actualizar";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created: async function() {
    this.filtros[1].lista = this.tipoestado = await this.$apiService.index("sistema/maestra/index/ESTADOS_CITAS").then(data => data);
    await this.search(this.filtros);
    this.contratos = await this.$apiService.index("sistema/contratos/index").then(data => data);
    this.empleados = await this.$apiService.index("sistema/personas/index/empleados").then(data => data);
    this.form.citaFecha = new Date().yyyymmdd();
    this.form.citaTipoEstado = this.tipoestado.find(e => e.comaNombrecorto == 'ACTIVA').comaId;
  },

  methods: {
    cambiar() {
      this.nuevo = !this.nuevo;
      this.form.citaFecha = null;
      this.form.cbdpId = null;
      this.form.cocuId = null;
      this.form.cocoId = null;
    },
    getPersona(coppId) {
      return this.pacientes.filter(e => e.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId == coppId)[0].copeId;
    },
    async searchCup() {
      this.cups.isLoading = true;
      if (this.cups.search.length > 0 && this.cups.search.toString().replace(/ /g, "") != "") {
        this.$apiService.index("sistema/cups/buscar/" + this.cups.search.toUpperCase()).then(data => {
          this.cups.isLoading = false;
          this.cups.cups = data;
        });
      }

    },
    async searchPaciente() {
      this.isLoading = true;
      if (this.searchP.length > 0 && this.searchP.toString().replace(/ /g, "") != "") {
        this.$apiService.index("sistema/personas/buscar2/pacientes/" + this.searchP.toUpperCase()).then(data => {
          this.isLoading = false;
          this.pacientes = data;
          this.guardopaciente = false;
        });
      }

    },
    async cargarEps() {
      this.$apiService.index("cita/basedatospacientes/index/" + this.form.coppId + "/" + new Date().yyyymmdd()).then(data => {
        if (data.length > 0) {
          this.bd = data;
        } else {
          this.bd = [];
        }

      });
    },
    async vaciarpaciente(respuesta) {
      if (respuesta == null) {
        this.cambio = null;
        this.datosVaciarPaciente = null;
        this.$nextTick().then(() => {
          if (this.form.coppId) {
            this.cambio = this.pacientes.filter(e => e.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId == this.form.coppId)[0].copeId;
            this.pacienteactual = this.pacientes.filter(e => e.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId == this.form.coppId)[0];
            this.datosVaciarPaciente = {}
            this.datosVaciarPaciente.copeId =this.cambio;
            this.datosVaciarPaciente.nuevo = this.nuevo;
            // console.log(((new Date().getTime() - new Date(this.pacienteactual.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000));
          }
        });
      } else {
        if (respuesta.tipo == 'nuevo') {
          this.pacientes.push(respuesta.paciente);
          this.form.coppId = respuesta.paciente.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId;
          this.guardopaciente = true;
          this.datosVaciarPaciente = null;
        } else {
          this.guardopaciente = true;
          this.cambio = null;
          this.datosVaciarPaciente = null;
          this.$nextTick().then(() => {
            if (this.form.coppId) {
              this.cambio = this.pacientes.filter(e => e.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId == this.form.coppId)[0].copeId;
              this.pacienteactual = this.pacientes.filter(e => e.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId == this.form.coppId)[0];
              this.datosVaciarPaciente = {}
            this.datosVaciarPaciente.copeId =this.cambio;
            this.datosVaciarPaciente.nuevo = this.nuevo;
              // console.log(((new Date().getTime() - new Date(this.pacienteactual.tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppFechanacimiento.replace(/-+/g, '/')).getTime()) / 86400000));
            }
          });
        }
      }

      if (this.form.coppId) {
        await this.cargarEps();
      }
    },
      async search(filtros) {
            this.lista = this.listaCopia = [];
            this.loadingTable = true;
            if(this.coppId){
              filtros[2].value = this.coppId;
            }
            const data = await this.$apiService.index(`cita/citas/buscar/1?${this.filtrar(filtros,this.page)}`).then(data => data);
            if (data) {
                this.lista = this.listaCopia = data.rows;
                this.Count = data.count;
                this.pageCount = Math.ceil((data.count) / 10);
            }
            this.loadingTable = false;
        },
    async agregar() {
      this.$v.form.$touch();
      if (!this.$v.form.$error && (this.form.cocoId || this.form.cbdpId)) {
        try {
          if (this.isNew) {
            let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);
            if (data) {
              this.listaCopia = this.lista.push(data);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
          } else {
            var id = this.form.citaId;
            let data = await this.$apiService.update(this.url + "update/" + id + "/", this.form).then(data => data);

            if (data) {
              this.lista = this.lista.filter(e => e.citaId != id);
              this.listaCopia = this.lista.push(data);
              this.$swal.fire({
                title: "Completado!",
                text: "Registro exitoso",
                icon: "success",
                confirmButtonText: "Ok"
              });
            }
          }
          this.close();
        } catch (error) {
          this.$swal.fire({
            title: "Algo sucedio!",
            text: "contacta con el administrador",
            icon: "error"
          });
        }

      }
    },
    async cancelar(id) {
      try {
        let data = await this.$apiService.update(this.url + "cancelar/" + id + "/", null).then(data => data);

        if (data) {
          this.lista = this.lista.filter(e => e.citaId != id);
          this.listaCopia = this.lista.push(data);
          this.$swal.fire({
            title: "Completado!",
            text: "Cita cancelada",
            icon: "success",
            confirmButtonText: "Ok"
          });
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async eliminar(id) {
      try {
        var result = await this.$swal.fire({
          title: "Estas seguro?",
          text: "No podras revertir esto!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, eliminar!"
        }).then(result => result.value);
        if (result) {
          let data = await this.$apiService.delete(this.url + "delete/" + id + "/").then(data => data);
          if (data) {
            this.listaCopia = this.lista = this.lista.filter(
              e => e.citaId != id
            );
            this.$swal.fire(
              "Eliminado!",
              "ha sido eliminado con exito.",
              "success"
            );
          }
        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async editar(id) {
      try {
        this.form = await this.$apiService.index(this.url + "view/" + id).then(data => data);
        this.isNew = false;
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }
    },
    ingresar(item) {
            try {
                this.ingreso = null;
                this.$nextTick().then(() => {
                    this.ingreso = {item:item};
                });
                this.dialog3 = true;
            } catch (error) {
                console.log(error)
                this.$swal.fire({
                    title: "Error!",
                    text: "contacte con el administrador",
                    icon: "error"
                });
            }
    },
    limpiar() {
      this.form = {

        citaFecha: this.currentDate(new Date()),
        createdBy: this.user.id,
        citaTipoEstado: null,
        citaTipo: 1,

        coppId: null,
        cbdpId: null,
        cocuId: null,
        cocoId: null
      };
    },
    close() {
      this.cambio = null;
      this.guardopaciente = false;
      this.isNew = true;
      this.dialog = false;
      this.limpiar();
    }
  }
};

</script>
