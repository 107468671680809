<template>
    <div>
        <v-tabs v-model="tab" background-color="grey darken-3 accent-4" centered dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-1" v-if="vistas.find(e=>e.p == 'verCitas')">
                Citas
                <v-icon>mdi-calendar</v-icon>
            </v-tab>
            <v-tab href="#tab-2" v-if="vistas.find(e=>e.p == 'verLaboratorios')">
                Laboratorios
                <v-icon>mdi-format-color-fill</v-icon>
            </v-tab>
            <v-tab href="#tab-3" v-if="vistas.find(e=>e.p == 'verProcedimientos')">
                Procedimientos
                <v-icon>mdi-account-box</v-icon>
            </v-tab>
            <v-tab href="#tab-4" v-if="vistas.find(e=>e.p == 'verOrdenesservicios')">
                Orden de servicios
                <v-icon>mdi-mouse-variant</v-icon>
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" style="background:rgb(220, 223, 224);">
            <v-tab-item value="tab-1" v-if="vistas.find(e=>e.p == 'verCitas')">
                <Citas :coppId="coppId" />
            </v-tab-item>
            <v-tab-item value="tab-2" v-if="vistas.find(e=>e.p == 'verLaboratorios')">
                <Laboratorios :coppId="coppId" />
            </v-tab-item>
            <v-tab-item value="tab-3" v-if="vistas.find(e=>e.p == 'verProcedimientos')">
                <Procedimientos :coppId="coppId"/>
            </v-tab-item>
            <v-tab-item value="tab-4" v-if="vistas.find(e=>e.p == 'verOrdenesservicios')">
                <Ordenesservicios :coppId="coppId" />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import Procedimientos from "./Procedimientos";
import Laboratorios from "./Laboratorios";
import Ordenesservicios from "./Ordenesservicios";
//import Medicamentos from "./Medicamentos";
import Citas from "./Citas";
import generales from '../../mixins/generales.js';
export default {
  props:{
    copeId:Number,
    coppId:Number,
  },
  components: {
    Citas,
    Procedimientos,
    Laboratorios,
    Ordenesservicios
    // Medicamentos
  },
  mixins: [generales],
  data() {
    return {
      tab: "tab-1",
      vistas: [],
      apiService: null
    }
  },
  created: async function() {
      if(this.getPermiso('verOrdenesservicios'))
      this.vistas.push({
        p: 'verOrdenesservicios',
        tab: 4
      });
      if(this.getPermiso('verProcedimientos'))
      this.vistas.push({
        p: 'verProcedimientos',
        tab: 3
      });
      if(this.getPermiso('verLaboratorios'))
      this.vistas.push({
        p: 'verLaboratorios',
        tab: 2
      });
      if(this.getPermiso('verCitas'))
      this.vistas.push({
        p: 'verCitas',
        tab: 1
      });

      if (this.$route.params.tab) {
        this.tab = this.$route.params.tab;
      } else {
        if (this.vistas.length > 0) {
          this.tab = "tab-" + this.vistas[this.vistas.length - 1].tab;
        }
      }
  },

}

</script>

<style>

</style>
